import { render, staticRenderFns } from "./protected.vue?vue&type=template&id=64e954df&scoped=true"
import script from "./protected.vue?vue&type=script&lang=js"
export * from "./protected.vue?vue&type=script&lang=js"
import style0 from "./protected.vue?vue&type=style&index=0&id=64e954df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e954df",
  null
  
)

export default component.exports