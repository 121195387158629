<template>
  <v-app class="layout-bg" app>
    <v-overlay v-model="overlay">
      <v-progress-circular
        size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer
      floating
      :width="230"
      v-model="drawer"
      :style="{ zIndex: overlay ? '1' : '' }"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <app-logo></app-logo>
        </v-list-item-content>
      </v-list-item>
      <v-list-item style="margin-bottom: 40px !important">
        <v-avatar size="60">
          <v-img :src="user['image'] ? user['image'] : avatar"></v-img>
        </v-avatar>
        <div style="position: absolute; bottom: 0; left: 55px">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="primary white--text rounded-circle pointer float-right"
                size="20"
              >
                mdi-chevron-down
              </v-icon>
            </template>
            <v-list class="settings-dropdown">
              <template v-if="isAdmin">
                <v-list-item
                  link
                  :to="link.to"
                  exact
                  v-for="(link, index) in conditionalLinks"
                  :key="index"
                >
                  <v-list-item-icon class="text-12">
                    <v-icon class="s-dropdown-icon">{{ link.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="text-12 ml-n7">
                    {{ link.title }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item link exact active-class="active-link">
                <v-list-item-icon class="active-text text-12">
                  <v-icon class="s-dropdown-icon">mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-12 ml-n7" @click="logout">
                  Logout
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <small class="ml-2">{{ user.name }}</small>
      </v-list-item>
      <v-list dense :class="!isAdmin ? 'mt-6' : ''">
        <v-list-item
          v-for="(link, i) in filteredLinks"
          :key="i"
          :to="link.to"
          link
          exact
          active-class="active-link"
          class="mt-n1"
        >
          <v-list-item-icon class="active-text text-12">
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            :class="pageName === link.title ? 'text-12 active-text' : 'text-12'"
          >
            {{ link.title }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            isAdmin &&
            (salesAccount ||
              user['account_type'].toLowerCase().includes('appsu') ||
              lifetimeTeamMembers)
          "
          class="mt-n1"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div style="display: flex; width: 100%" v-bind="attrs" v-on="on">
                <v-list-item-icon class="text-12">
                  <v-icon
                    :color="
                      ['Team Members', 'Users'].includes(pageName)
                        ? '#6c9af5'
                        : ''
                    "
                    >mdi-account-group-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content
                  :class="
                    ['Team Members', 'Users'].includes(pageName)
                      ? 'text-12 active-text mt-1'
                      : 'text-12 mt-1'
                  "
                >
                  Users
                  <v-icon
                    size="medium"
                    :color="
                      ['Team Members', 'Users'].includes(pageName)
                        ? '#6c9af5'
                        : ''
                    "
                    style="position: relative; bottom: 17px"
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-list-item-content>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-if="user['account_type'].toLowerCase().includes('appsu')"
                link
                to="/team-members"
                exact
                active-class="active-link"
              >
                <v-list-item-content
                  :class="
                    pageName === 'Team Members'
                      ? 'text-12 active-text'
                      : 'text-12'
                  "
                >
                  Team Members
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="salesAccount || lifetimeTeamMembers"
                link
                to="/reseller-license"
                exact
                active-class="active-link"
              >
                <v-list-item-content
                  :class="
                    pageName === 'Users' ? 'text-12 active-text' : 'text-12'
                  "
                >
                  Reseller License
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <v-list-item
          to="/training"
          link
          exact
          active-class="active-link"
          :class="
            isAdmin &&
            (salesAccount ||
              user['account_type'].toLowerCase().includes('appsu'))
              ? 'mt-n4'
              : 'mt-n1'
          "
        >
          <v-list-item-icon class="active-text text-12">
            <v-icon>mdi-book-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            :class="pageName === 'Training' ? 'text-12 active-text' : 'text-12'"
          >
            Training
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense class="bottom-container">
        <v-list-item class="sidenav-btm-box">
          <v-btn
            to="/support"
            class="secondary black--text capitalize sidenav-btm"
            small
            rounded
            text
          >
            <v-icon class="mr-1 bottom-nav-icon"
              >mdi-message-text-outline</v-icon
            >
            Support
          </v-btn>
        </v-list-item>
        <v-list-item class="sidenav-btm-box">
          <v-btn
            to="/account-upgrade"
            class="primary capitalize sidenav-btm"
            small
            rounded
            text
          >
            <v-icon class="mr-1 bottom-nav-icon">mdi-creation</v-icon> Upgrade
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      color="secondary"
      flat
      app
      :style="{ zIndex: overlay ? '1' : '' }"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-h6 text-capitalize pl-2">
        {{ $route.name !== "UserDetails" ? pageName : user["fullname"] }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="
          ['Received Recordings', 'My Recordings', 'Requested Links'].includes(
            pageName
          )
        "
        rounded
        v-model="searchRecordings"
        dense
        solor
        class="white"
        style="max-width: 300px; height: 38px"
        placeholder="Search with name or label"
        @keyup="searchRecording"
      >
        <template v-slot:prepend-inner>
          <v-icon class="secondary rounded-xl mt-1 pa-1 ml-n5"
            >mdi-magnify</v-icon
          >
        </template>
      </v-text-field>
      <div class="mr-2 ml-2">
        <v-btn
          @click="openRecordLink()"
          text
          class="primary rounded-xl white--text capitalize"
        >
          <v-icon class="white primary--text rounded-xl pa-1 mr-1 ml-n3" small>
            mdi-radiobox-marked
          </v-icon>
          record
        </v-btn>
        <v-btn
          @click="$refs.new_request.openDialog()"
          text
          class="primary rounded-xl white--text capitalize ml-2"
        >
          <v-icon class="white primary--text rounded-xl pa-1 mr-1 ml-n3" small>
            mdi-plus
          </v-icon>
          new request
        </v-btn>
        <new-request ref="new_request"></new-request>
      </div>
    </v-app-bar>
    <v-main>
      <v-container class="mt-4">
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="white">
      <v-card-text>
        &copy; {{ app_name + " " + new Date().getFullYear() }}
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import AppLogo from "../components/Logo";
import NewRequest from "../components/NewRequest";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    AppLogo,
    NewRequest,
  },
  name: "protected",
  data() {
    return {
      avatar: require("../assets/avatar.png"),
      app_name: process.env.VUE_APP_NAME,
      links: [
        {
          to: "/dashboard",
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
        },
        {
          to: "/my-recordings",
          title: "My Recordings",
          icon: "mdi-play-circle-outline",
        },
        {
          to: "/received-recordings",
          title: "Received Recordings",
          icon: "mdi-briefcase-check-outline",
        },
        {
          to: "/requested-links",
          title: "Requested Links",
          icon: "mdi-movie-play-outline",
        },
        {
          to: "/my-folders",
          title: "My Folders",
          icon: "mdi-folder-account",
        },
        {
          to: "/contacts",
          title: "Contacts",
          icon: "mdi-account-multiple-outline",
        },
      ],
      profileLinks: [
        {
          to: "/account-settings",
          title: "Account Settings",
          icon: "mdi-cog-outline",
        },
        {
          to: "/payment-history",
          title: "Payment History",
          icon: "mdi-clipboard-list-outline",
        },
        {
          to: "/record-settings",
          title: "Record Settings",
          icon: "mdi-cellphone-cog",
        },
        {
          to: "/custom-domain-v2",
          title: "Custom Domain",
          icon: "mdi-web",
        },
      ],
      drawer: !!this.$vuetify.breakpoint.mdAndUp,
      searchRecordings: "",
      overlay: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      default_domain: "domain/default_domain",
    }),
    isAdmin() {
      const user = this.user;
      return !!(
        !user["active_user"] || user["active_user"]["roles"] === "admin"
      );
    },
    filteredLinks() {
      const links = this.links;
      if (!this.isAdmin) return links.filter((x) => x.title !== "Contacts");
      return links;
    },
    pageName() {
      let route = this.$route.name;
      let pageName = "";
      let excludedRoutes = [
        "RecordScreen",
        "MyRecordingDetails",
        "NewRequest",
        "SendScreenRecording",
        "AccountUpgrade",
        "PaymentMethod",
      ];
      if (!excludedRoutes.includes(route)) {
        pageName = this.$route.name.replace(/([A-Z])/g, " $1").trim();
      }

      return pageName;
    },
    salesAccount() {
      const account = this.user.account_type;
      if (!account) {
        return;
      }
      return !!(
        account === "Pro Basic" ||
        account === "Pro Premium" ||
        account.includes("Pro")
      );
    },
    lifetimeTeamMembers() {
      const account = this.user.account_type;
      if (!account) {
        return;
      }
      return !!(
        account === "Business Lifetime" || account === "Enterprise Lifetime"
      );
    },
    conditionalLinks() {
      if (this.showCustomDomain()) {
        return this.profileLinks;
      } else {
        return this.profileLinks.filter((x) => x.title !== "Custom Domain");
      }
    },
  },
  methods: {
    ...mapMutations({
      LOGOUT: "auth/LOGOUT",
      CLEAR_DEFAULT_DOMAIN: "domain/CLEAR_DEFAULT_DOMAIN",
      CLEAT_SETTINGS: "settings/CLEAR_SETTINGS",
    }),
    async logout() {
      this.overlay = true;
      await this.$axios.post("/user/logout");
      this.LOGOUT();
      this.CLEAR_DEFAULT_DOMAIN();
      this.CLEAT_SETTINGS();
      await this.$router.replace({ name: "Login" });
    },
    searchRecording() {
      if (this.searchRecordings.length > 0 || this.searchRecordings === "") {
        this.$root.$emit("search", this.searchRecordings);
      }
    },
    showCustomDomain() {
      const account = this.user.account_type;
      if (!account) {
        return;
      }
      return !!(
        account === "Business Lifetime" ||
        account === "Enterprise Lifetime" ||
        account === "Enterprise" ||
        account === "Pro Premium" ||
        account === "Pro Basic" ||
        account.includes("Pro") ||
        account.toLowerCase().includes("appsu")
      );
    },
    openRecordLink() {
      let url = null;
      if (this.default_domain.name) {
        const host = this.default_domain.name;
        const protocol =
          process.env.NODE_ENV === "production" ? "https://" : "http://";
        url = `${protocol}${host}/record`;
        window.open(url);
      } else {
        url = `${process.env.VUE_APP_URL}/record`;
        window.open(url);
      }
    },
  },
};
</script>

<style scoped>
.layout-bg {
  background: #eff3ff;
}
.sidenav-btm-box {
  /* border: 1px solid red !important; */
  width: 100% !important;
  text-align: left !important;
}
.sidenav-btm {
  border: 1px solid #ddd !important;
  width: 100% !important;
  height: 35px !important;
}
.bottom-nav-icon {
  font-size: 18px !important;
}
.bottom-container {
  width: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}
.settings-dropdown {
  /* border: 1px solid #f00 !important; */
  padding: 0px !important;
}
.s-dropdown-icon {
  font-size: 17px !important;
}
</style>
