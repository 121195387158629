<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="pa-5" flat>
        <v-toolbar class="d-flex justify-center font-weight-bold" flat>
          New Request
        </v-toolbar>
        <v-form lazy-validation ref="form" class="text-left">
          <label>Link Label <span class="error--text">*</span></label>
          <v-text-field
            solo
            dense
            rounded
            flat
            type="text"
            background-color="secondary"
            v-model="form.label"
            placeholder="Enter link label"
            :rules="validate.required('Link label')"
          ></v-text-field>
          <div v-if="record_link !== ''">
            <label>Here is the screen recording link</label>
            <v-text-field
              solo
              dense
              rounded
              flat
              background-color="secondary"
              v-model="record_link"
              readonly
              id="videoLink"
            >
              <template v-slot:append>
                <v-icon
                  @click="copyLink"
                  size="16"
                  class="white rounded-xl mr-n5 pa-2 pointer"
                >
                  mdi-content-copy
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-form>
        <v-card-actions v-if="record_link === ''" class="d-flex justify-center">
          <v-btn
            @click="$refs.form.validate() ? createRequest() : null"
            class="capitalize primary px-8"
            rounded
            large
            :loading="loadRequest"
          >
            create request
          </v-btn>
          <v-btn
            class="capitalize secondary elevation-0 px-5 grey--text"
            rounded
            large
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
        <div v-if="record_link !== ''" class="text-left mt-4">
          <div class="mb-3">Share</div>
          <div
            class="ml-3 capitalize black--text pointer text-center share-btns"
            @click="whatsappShare"
          >
            <v-img
              class="img-icon"
              :src="require('@/assets/icons/whatsapp.png')"
            ></v-img>
            <span>WhatsApp</span>
            <ShareNetwork
              id="whatsapp"
              network="whatsapp"
              :url="record_link"
              :title="form.label + ' Record request link from Konvey'"
              :description="
                'Hi, kindly use ' +
                record_link +
                ' to record and share your screen/camera.'
              "
            ></ShareNetwork>
          </div>
          <div
            class="ml-3 capitalize black--text pointer text-center share-btns"
            @click="facebookShare"
          >
            <v-img
              class="img-icon"
              :src="require('@/assets/icons/facebook.png')"
            ></v-img>
            <span>Facebook</span>
            <ShareNetwork
              id="facebook"
              network="facebook"
              :url="record_link"
              :title="form.label + ' Record request link from Konvey'"
              :description="
                'Hi, kindly use ' +
                record_link +
                ' to record and share your screen/camera.'
              "
            ></ShareNetwork>
          </div>
          <div
            class="ml-3 capitalize black--text pointer text-center share-btns"
            @click="mailShare"
          >
            <v-img
              class="img-icon"
              :src="require('@/assets/icons/mail.png')"
            ></v-img>
            <span>Email</span>
            <ShareNetwork
              id="mail"
              network="email"
              :url="record_link"
              :title="form.label + ' Record request link from Konvey'"
              :description="
                'Hi, kindly use ' +
                record_link +
                ' to record and share your screen/camera.'
              "
            ></ShareNetwork>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormValidation from "../utils/FormValidation";
import ResponseHelper from "../utils/ResponseHelper";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewRequest",
  data() {
    return {
      form: {
        request_type: "link",
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      categories: [],
      loading: false,
      loadRequest: false,
      dialog: false,
      record_link: "",
    };
  },
  computed: {
    ...mapGetters({
      default_domain: "domain/default_domain",
    }),
  },
  methods: {
    ...mapActions({
      get_categories: "settings/get_categories",
      new_request: "recordings/new_request",
    }),
    async createRequest() {
      this.loadRequest = true;
      if (this.default_domain.name) {
        const protocol =
          process.env.NODE_ENV === "production" ? "https://" : "http://";
        this.form.domain = protocol + this.default_domain.name;
      }
      try {
        const response = await this.new_request(this.form);
        this.loadRequest = false;
        this.toast.sendSuccess(response);
        this.record_link = response.data.record_link;
      } catch (e) {
        this.loadRequest = false;
        this.toast.sendError(e);
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    copyLink() {
      let link = document.getElementById("videoLink");
      link.select();
      document.execCommand("copy");
      this.closeDialog();
      this.$toast.success("Link copied to clipboard");
    },
    whatsappShare() {
      document.querySelector("#whatsapp").click();
      this.closeDialog();
    },
    facebookShare() {
      document.querySelector("#facebook").click();
      this.closeDialog();
    },
    mailShare() {
      document.querySelector("#mail").click();
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
.share-btns {
  display: inline-block;
}
.share-btns .img-icon {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.share-btns span {
  display: block;
  font-size: 13px;
  font-weight: 500;
}
</style>
